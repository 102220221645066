<template>
    <v-container>
      <v-row>
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">Settings do seu frontoffice</p>
              <p class="page-subtitle">Imagem e vídeo</p>
            </v-col>
        </v-row>
        <v-card>
            <v-row class="pa-2 ma-2">
                <v-col cols="12" align="left">
                    <span class="text-h6">Escolha se pretende que sejam carregadas imagens ou vídeos no frontend</span>
                </v-col>
                <v-col cols="12">
                    <v-switch v-model="fields.video" label="Ativar vídeo"></v-switch>
                </v-col>
                <v-col cols="12" align="left">
                    <span class="text-h6">Imagens</span>
                    <v-divider></v-divider>
                    <v-col cols="12">
                        <label class="text-caption">Atenção! As imagens devem seguir um tamanho de 1064 x 708 no minimo</label>
                    </v-col>
                </v-col>
                <v-col cols="12" v-if="checkedLoaded">
                    <span class="text-body-1">Existem imagens carregadas a serem usadas no Front</span>
                </v-col>
                <v-col cols="12" v-if="checkedLoaded">
                    <v-btn @click="cleanSheet()">Limpar imagens</v-btn>
                </v-col>
                <v-col cols="12">
                    <v-file-input v-model="fields.image1" label="Imagem 1"></v-file-input>
                </v-col>
                <v-col cols="12">
                    <v-file-input v-model="fields.image2" label="Imagem 2"></v-file-input>
                </v-col>
                <v-col cols="12">
                    <v-file-input v-model="fields.image3" label="Imagem 3"></v-file-input>
                </v-col>
                <v-col cols="12" align="left">
                    <span class="text-h6">Desativar rotação de imagens no Front (se ativo só irá aparecer uma imagem como cover)</span>
                </v-col>
                <v-col cols="12">
                    <v-switch v-model="fields.soloImage" label="Mostrar só uma imagem"></v-switch>
                </v-col>
                <v-col cols="12"  v-if="loadImage.i1 != null || loadImage.i2 != null || loadImage.i3 != null">
                    <v-row>
                        <v-col cols="12" md="4" v-if="loadImage.i1 != null">
                            <v-card>
                                <v-img :src="'https://api.la-agency.com/media/i/frontend/' + loadImage.i1" height="400" cover></v-img>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4" v-if="loadImage.i2 != null">
                            <v-card>
                                <v-img :src="'https://api.la-agency.com/media/i/frontend/' + loadImage.i2" height="400" cover></v-img>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4" v-if="loadImage.i3 != null">
                            <v-card>
                                <v-img :src="'https://api.la-agency.com/media/i/frontend/' + loadImage.i3" height="400" cover></v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" align="left">
                    <span class="text-h6">Vídeo</span>
                    <v-divider></v-divider>
                    <v-col cols="12">
                        <label class="text-caption">Atenção! Deve colocar aqui apenas o ID do vimeo. Este ID é um identificador do vídeo que está visível no link</label>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="fields.videoLink" label="Video"></v-text-field>
                    </v-col>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-row>
                    <v-col cols="12">
                        <v-btn @click="submit">Gravar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-dialog
            v-model="snackbar"
            width="500"
            persistent
            >
            <v-card>
                <v-card-title class="text-h5 success white--text">
                Sucesso ao enviar imagens
                </v-card-title>
                <v-card-text>
                <v-btn style="margin: auto; display: block"
                    color="primary"
                    block
                    @click="reload"
                >
                    Reload page
                </v-btn>
                </v-card-text>
            </v-card>
            </v-dialog>
    </v-container>   
</template>

<script>
import mixin from '@/models/frontend/FrontendModel'

export default {
    name: 'Frontpage',
    mixins: [mixin],
    data(){
        return{
            snackbar: false,
            timeout: 2000,
            dataLoading: false,
            fields:{
                image1: null,
                image2: null,
                image3: null,
                videoLink: null,
                video: false,
                soloImage: false
            },
            loadImage:{
                i1: null,
                i2: null,
                i3: null
            },
            homeInfo: null,
            checkedLoaded: false
        }
    },
    mounted(){
        //https://api.la-agency.com/media/i/frontend/
        axios.get('/front/homepage').then(({data}) => {
            if(data.covers.length > 0){
                this.fields.videoLink = data.covers[0].videoLink
                this.fields.video = parseInt(data.covers[0].video)

                this.fields.soloImage = parseInt(data.covers[0].soloImage)
                    

                if(data.covers[0].image1 != ''){
                    this.loadImage.i1 = data.covers[0].image1
                    this.checkedLoaded = true
                }
                if(data.covers[0].image2 != ''){
                    this.loadImage.i2 = data.covers[0].image2
                    this.checkedLoaded = true
                }
                if(data.covers[0].image3 != ''){
                    this.loadImage.i3 = data.covers[0].image3
                    this.checkedLoaded = true
                }
            }
        })
    },
    methods:{
        reload(){
            window.location.reload()
        },
        cleanSheet(){
            this.fields = {
                image1: null,
                image2: null,
                image3: null,
                videoLink: null,
                video: false,
                soloImage: false
            }

            this.submit()
        },
        submit(){
            this.save(this.fields)
            this.snackbar = true
        }
    }
}
</script>
