import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Frontend action called.`)
  },

  methods: {
    ...mapActions(['setFrontend', 'getHomepageData']),

    async save(form) {
      try {
        // Updating item
        await this.setFrontend({ 'data': form })
      } catch (error) {
        this.dataTableLoading = false
      }
    },
    async getHomepageData() {
      try {
        // Updating item
        await this.getHomepageData()
      } catch (error) {
        this.dataTableLoading = false
      }
    }
  }
}

export default mixin